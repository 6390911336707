import React from 'react';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import Messages from '../Messages';
import Background from '../../images/bg-01.jpg';
import '../../css/util.css';
import GetUrl from '../Users/GetUrl';

const HomePage = () => (
  <div class="limiter">
      <div class="container-login100" style={{
      backgroundImage: `url(${Background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
      }} >
        <div class="wrap-login100 p-l-20 p-r-20 p-t-62 p-b-33">
        <h1>MTSC Temperature Record</h1>
        <p>Please key in your temperature and click Send .</p>
      
          <GetUrl/>
          <Messages />
        </div>
      </div>
  </div>
);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
