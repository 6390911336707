import React from "react";
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";


export default function QueryParamsExample() {
  return (
    <Router>
      <QueryParamsDemo />
    </Router>
  );
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QueryParamsDemo() {
  let query = useQuery();

  return (
    <div>
      <div>
        <Child name={query.get("name")} />
      </div>
    </div>
  );
}

function Child({ name }) {
  return (
    <div>
      {name ? (
        <h3>
          You are now at &quot;{name}
          &quot;
        </h3>
      ) : (
        <h3>You did not Scan QR location</h3>
      )}
    </div>
  );
}